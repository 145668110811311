/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Row, Col, Button, Nav } from "react-bootstrap"
import clsx from 'clsx';

import Header from "./header"
import Navbar from "./navBar"
import logoIcon from '../images/logo_icon.svg';
import logo from '../images/logo.svg';
import { IoIosMail, IoIosPin } from 'react-icons/io';
import { MdPhone } from 'react-icons/md';
import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa'

import { DiCode } from 'react-icons/di';


const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Navbar pageInfo={pageInfo} />
        <div className={clsx('main', pageInfo && pageInfo.pageName, 'mt-4')}>
          <main>{children}</main>
        </div>
        <Container className='da-footer'>
          <footer className='mt-5'>
            <Row>
              <Col xs={6} className='d-flex align-items-center footer__social justify-content-start'>
                <a href='https://linkedin.com/in/fullofcaffeine'><FaLinkedin /></a>
                <a href='https://github.com/fullofcaffeine'><FaGithub /></a>
                <a href='https://twitter.com/fullofcaffeine'><FaTwitter /></a>
              </Col>
              <Col xs={6} className='d-flex align-items-center justify-content-end'>
                <span style={{ fontSize: '12px' }}>
                  Ⓒ serpa.codes,  {new Date().getFullYear()}.
                </span>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    )}
  />
)

export default Layout
